import { CSSProperties } from "react";
import { Image, Row } from "react-bootstrap";
declare interface SeparatorProps {
  backgroundColor?: string;
  backgroundColor2?: string;
  img?: string;
  style?: CSSProperties | undefined;
}
export default function Separator(props: SeparatorProps) {
  function colorTransition() {
    return props.backgroundColor && props.backgroundColor2 ? (
      <div
        style={{
          height: "5cqh",
          width: "100%",
          background:
            "linear-gradient(180deg, var(--" +
            props.backgroundColor +
            ") 0%, var(--" +
            props.backgroundColor2 +
            ") 100%",
        }}
      />
    ) : (
      ""
    );
  }

  function imgTransition() {
    return props.img ? (
      <Image
        src={props.img}
        style={{
          width: "90%",
          ...props.style,
        }}
      />
    ) : (
      ""
    );
  }
  return (
    <Row
      style={{
        backgroundColor: "var(--" + props.backgroundColor + ")",
        width: "100%",
        margin: "0px",
      }}
    >
      {colorTransition()}
      {imgTransition()}
    </Row>
  );
}
