import { Element } from "react-scroll";
import { Carousel, Image } from "react-bootstrap";
import homeImage from "../../assets/home/home-resharp.png";
import Img2014 from "../../assets/home/2014.jpg";
import Img2015 from "../../assets/home/2015.jpg";
import Img2016 from "../../assets/home/2016.jpg";
import Img2017 from "../../assets/home/2017.jpg";
import Img2018 from "../../assets/home/2018.jpg";
import Img2019 from "../../assets/home/2019.jpg";
import Img2020 from "../../assets/home/2020.jpg";
import Img2021 from "../../assets/home/2021.jpg";
import Img2022 from "../../assets/home/2022.jpg";
import Img2023 from "../../assets/home/2023.jpg";

declare interface YearImage {
  img: string;
  year?: number;
}

function LandingPage() {
  const yearImages: YearImage[] = [
    { img: homeImage },
    { img: Img2014, year: 2014 },
    { img: Img2015, year: 2015 },
    { img: Img2016, year: 2016 },
    { img: Img2017, year: 2017 },
    { img: Img2018, year: 2018 },
    { img: Img2019, year: 2019 },
    { img: Img2020, year: 2020 },
    { img: Img2021, year: 2021 },
    { img: Img2022, year: 2022 },
    { img: Img2023, year: 2023 },
  ];

  return (
    <Element name="home">
      <Carousel
        slide={true}
        pause="hover"
        nextLabel=""
        prevLabel=""
        interval={null}
        indicators={false}
      >
        {yearImages.map((yearImage) => (
          <Carousel.Item key={yearImage.year || "default"}>
            <Carousel.Caption>
              <h3>{yearImage.year}</h3>
            </Carousel.Caption>
            <Image
              className="slanted landing-image"
              src={yearImage.img}
            ></Image>{" "}
          </Carousel.Item>
        ))}
      </Carousel>
    </Element>
  );
}

export default LandingPage;
