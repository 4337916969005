import "./App.scss";
import Navbar from "./components/navigation";
import Locations from "./components/sections/locations/locations";
import Ansprechpartner from "./components/sections/partner/ansprechpartner";
import Invitation from "./components/sections/invitation";
import LandingPage from "./components/sections/landing-page";
import Login from "./components/login/login";
import Separator from "./components/separator";
import RSVP from "./components/sections/rsvp";
import Koeln from "./assets/flowers/koeln.png";

function App() {
  return (
    <div className="app">
      <Login>
        <Navbar />
        <LandingPage />
        <Invitation />
        <Separator
          img={Koeln}
          style={{
            filter: "opacity(.5)",
          }}
        />
        <Locations />        
        <RSVP />
        <Ansprechpartner />
      </Login>
    </div>
  );
}

export default App;
