import { Card, Col, Row } from "react-bootstrap";
import { MdOutlineCall } from "react-icons/md";

export interface AnsprechpartnerCardProps {
  name: string;
  image: string;
  role: string;
  kontakt?: string;
  kontakt2?: string;
}
export function AnsprechpartnerCard(
  props: Readonly<AnsprechpartnerCardProps>
): any {
  function createCallLink(number: string | undefined): JSX.Element | undefined {
    return number ? (
      <Col>
        <a href={"tel:" + number}>
          <MdOutlineCall className="icon" size="28px" />
        </a>
      </Col>
    ) : undefined;
  }

  return (
    <Card>
      <Card.Img variant="top" src={props.image} />
      <Card.Body style={{ display: "flex", flexDirection: "column" }}>
        <Row>
          <Card.Title className="fancy-font">{props.name}</Card.Title>
        </Row>
        <hr
          style={{
            borderTop: "1px solid rgba(0,0,0,.4)",
            width: "80%",
            marginTop: "0px",
            marginBottom: "5px",
          }}
        />
        <Row>
          <Col>
            <Card.Subtitle>{props.role}</Card.Subtitle>
          </Col>
          {createCallLink(props.kontakt)}
        </Row>
      </Card.Body>
    </Card>
  );
}
