import { useState } from "react";
import { Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-scroll";

function Navigation() {
  const [expanded, setExpanded] = useState(false);
  const offset = -50;

  function toggleExpand() {
    setExpanded(!expanded);
  }

  function close() {
    setExpanded(false);
  }

  return (
    <Col>
      <Navbar expanded={expanded} expand="lg" fixed="top">
        <Container>
          <Navbar.Brand>
            <Link
              to="home"
              smooth={true}
              className="fancy-font"
              onClick={close}
            >
              N & T
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={toggleExpand}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav style={{ flex: 1 }} className="justify-content-around nav">
              <Link
                to="invitation"
                smooth={true}
                onClick={close}
                offset={offset}
              >
                Einladung
              </Link>
              <Link
                to="locations"
                smooth={true}
                onClick={close}
                offset={offset}
              >
                Locations
              </Link>

              <Link to="Hotel" smooth={true} onClick={close} offset={offset}>
                Hotel
              </Link>
              <Link to="rsvp" smooth={true} onClick={close} offset={offset}>
                Rückmeldung
              </Link>
              <Link
                to="ansprechpartner"
                smooth={true}
                onClick={close}
                offset={offset}
              >
                Ansprechpartner
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Col>
  );
}

export default Navigation;
