import { useState } from "react";
import "./login.scss";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { IoKey } from "react-icons/io5";

declare interface LoginProps {
  children: any;
}
export default function Login(props: LoginProps): JSX.Element {
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [wrongPassword, setWrongPassword] = useState(false);

  function hash(value: string): number {
    var hash = 0,
      i,
      chr;
    if (value.length === 0) return hash;
    for (i = 0; i < value.length; i++) {
      chr = value.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  function checkPassword() {
    if (hash(currentPassword) === -1002700316) {
      setLoggedIn(true);
    } else {
      setWrongPassword(true);
    }
  }

  function pwChange(event: any): void {
    const newValue = event.currentTarget.value;
    setCurrentPassword(newValue);
    if (!newValue) {
      setWrongPassword(false);
    }
  }
  function loginPage(): JSX.Element {
    return (
      <Container className="login">
        <Col className="password-col">
          <div className="password-form">
            <Form.Label>Nadine und Timos Hochzeit</Form.Label>
            <Row style={{ flexWrap: "nowrap" }}>
              <Form.Control
                required
                type="password"
                value={currentPassword}
                onChange={pwChange}
                onKeyDown={(event) =>
                  event.key === "Enter" ? checkPassword() : null
                }
              />
              <Button className="pw-btn" onClick={() => checkPassword()}>
                <IoKey size={20} />
              </Button>
            </Row>
            {wrongPassword ? (
              <div className="wrong-pw">Flasches Passwort</div>
            ) : (
              ""
            )}
          </div>
        </Col>
      </Container>
    );
  }

  return <>{loggedIn ? props.children : loginPage()}</>;
}
