import { Button, Col, Container, Row } from "react-bootstrap";
import { Element } from "react-scroll";
import { LuMapPin } from "react-icons/lu";

interface LocationProps {
  name?: string;
  summary: string;
  description?: string;
  defaultImage?: string;
  googleLink?: string;
  style?: any;
  children?: any;
  onClick?: any;
}
export function Location(props: Readonly<LocationProps>) {
  function gmapButton() {
    return (
      <Button className="gmap-button" href={props.googleLink} target="_blank">
        <Row>
          {props.name}
          <LuMapPin size={"28px"} />
        </Row>
      </Button>
    );
  }
  return (
    <Element name={props.summary} className="location" style={props.style}>
      <Container
        className="location-details center "
        style={{
          backgroundImage: "url(" + props.defaultImage + ")",
        }}
        onClick={props.onClick}
      >
        <Col>
          <h1 className="overlay-header fancy-font">{props.summary}</h1>
          <hr style={{ borderTop: "3px solid rgba(0,0,0,.4)", width: "40%" }} />
          <h3 className="overlay-text">
            <Row style={{ justifyContent: "center" }}>
              {props.description}
              {props.googleLink ? gmapButton() : ""}
            </Row>
            {props.children}
          </h3>
        </Col>
      </Container>
    </Element>
  );
}
