import { useState } from "react";
import { Element } from "react-scroll";
import { Col, Row } from "react-bootstrap";
import SchlossWahnImage from "../../../assets/locations/schloss-wahn/default.png";
import EltzhofImage from "../../../assets/locations/eltzhof/default.png";
import GeislerImage from "../../../assets/locations/geisler/default.png";
import { IoInformationCircleSharp } from "react-icons/io5";
import ReactCardFlip from "react-card-flip";
import { Location } from "./location";
import HotelDetails from "./hotel-details";

function Locations() {
  const [flipped, setFlipped] = useState(false);

  function flipButton() {
    return (
      <Row>
        <h5>
          {flipped ? "" : "Weitere Infos "}
          {flipped ? "" : <strong>hier</strong>}
          <IoInformationCircleSharp size="28px" />
        </h5>
      </Row>
    );
  }

  return (
    <Element name="locations">
      <Col className="section locations">
        <Row className="section-header">Locations</Row>
        <Location
          name="Schloss Wahn"
          summary="Trauung"
          description="Eintreffen zu 15 Uhr am "
          defaultImage={SchlossWahnImage}
          googleLink="https://maps.app.goo.gl/8Bmt8td3PysFKcxo8"
        ></Location>
        <span />
        <Location
          name="Eltzhof"
          summary="Feier"
          description="Ab 17 Uhr im "
          defaultImage={EltzhofImage}
          googleLink="https://maps.app.goo.gl/NmVPerb9HfYD6Rad9"
        ></Location>
        <ReactCardFlip isFlipped={flipped}>
          <Location
            name="Hotel Garni Geisler"
            summary="Hotel"
            description="Übernachtung im "
            defaultImage={GeislerImage}
            googleLink="https://maps.app.goo.gl/qxkUh7f9NMTkAxvh7"
            onClick={(e: any) => setFlipped(!flipped)}
          >
            {flipButton()}
          </Location>
          <HotelDetails
            onClick={(e: any) => setFlipped(!flipped)}
          ></HotelDetails>
        </ReactCardFlip>
      </Col>
    </Element>
  );
}

export default Locations;
