import { Col, Container, Image, Row } from "react-bootstrap";
import { Element } from "react-scroll";
import { Timer } from "../timer";
import FlowerFrameUpper from "../../assets/flowers/frame-upper.png";
import FlowerFrameLower from "../../assets/flowers/frame-lower.png";

function Invitation() {
  function generateICSFile() {
    const icsContent = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
CLASS:PUBLIC
DESCRIPTION;ENCODING=QUOTED-PRINTABLE:Wir wollen mit euch unsere Hochzeit feiern.=0D=0AZeitplan:=0D=0A\t- 15: Uhr Trauung im Schloss Wahn=0D=0A\t- 17 Uhr: Essen und Feier im Eltzhof=0D=0A\t- 03 Uhr: Schlafen im Hotel Garni Geisler=0D=0AWir freuen uns auf euch!
LOCATION:Schloss Wahn
SUMMARY:Nadine und Timo Hochzeit
TRANSP:TRANSPARENT
DTSTART:20241005T130000Z
DTEND:20241006T010000Z
UID:846lx2ghnzh
DTSTAMP:20240117T203424Z
END:VEVENT
PRODID:datebook.dev
END:VCALENDAR`;
    // TODO TBE change test Details

    const blob = new Blob([icsContent], {
      type: "text/calendar;charset=utf-8",
    });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "hochzeit-bergerbusch.ics";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Element name="invitation" className="section invitation">
      <Col>
        <Container className="title" fluid onClick={generateICSFile}>
          <Row style={{ flexWrap: "nowrap" }}>
            <Col>SAVE</Col>
            <Col className="beautifully-delicious" style={{ zIndex: 1 }}>
              the
            </Col>
            <Col>DATE</Col>
          </Row>
          <Row style={{ justifyContent: "center" }} className="hochzeitstag">
            {""} – 05.10.2024 –
          </Row>
        </Container>
        <Image src={FlowerFrameUpper} className="flowerframe" fluid />
        <Timer />
        <Image src={FlowerFrameLower} className="flowerframe" fluid />
        {/*  */}
        <Container
          onClick={(_) =>
            window.open(
              "https://www.youtube.com/watch?v=sLpzy3zocbQ",
              "_blank",
              "noreferrer"
            )
          }
        >
          <h2
            className="fancy-font"
            style={{ marginTop: "3cqh", fontSize: "4cqh" }}
          >
            Der Countdown läuft!
          </h2>
          <h5>
            Wir heiraten und würden uns riesig freuen wenn Ihr mit uns diesen
            besonderen Tag feiert.
          </h5>
        </Container>
      </Col>
    </Element>
  );
}

export default Invitation;
