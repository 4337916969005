import { Location } from "./location";
import GeislerImage from "../../../assets/locations/geisler/default.png";

declare interface HotelDetailsProps {
  children?: any;
  onClick?: any;
}
export default function HotelDetails(props: HotelDetailsProps) {
  return (
    <Location
      summary="Hotel Garni Geisler"
      onClick={props.onClick}
      defaultImage={GeislerImage}
    >
      <h5>
        Da viele von euch eine längere Anreise haben werden, haben wir bereits
        über 25 Zimmer für euch reservieren lassen. Darunter sind sowohl
        Einzel-, Doppel-, als auch Twinbett-Zimmer. Das Hotel ist vom Eltzhof
        fußläufig in weniger als 5 Minuten erreichbar. Desweiteren besteht die
        Möglichkeit bereits Freitag anzureisen und/oder Sonntag einen
        Late-Checkout zu nutzen. Bei Interesse oder Fragen schickt uns gerne
        eine Nachricht.
      </h5>
      {props.children}
    </Location>
  );
}
