import { ReactNode } from "react";
import { Row } from "react-bootstrap";
import Countdown, { CountdownRenderProps } from "react-countdown";

export function Timer(): any {
  function padLeft(value?: number) {
    return ("" + value).padStart(2, "0");
  }

  function renderTime(props: CountdownRenderProps): ReactNode {
    return (
      <Row className="fancy-font" style={{ justifyContent: "center" }}>
        <Row>
          <div className="time-property">
            <h5>{padLeft(props.days)}</h5>
            <h5>Tage</h5>
          </div>
          <div className="time-property">
            <h5>{padLeft(props.hours)}</h5>
            <h5>Stunden</h5>
          </div>
        </Row>
        <Row>
          <div className="time-property">
            <h5>{padLeft(props.minutes)}</h5>
            <h5>Minuten</h5>
          </div>
          <div className="time-property">
            <h5>{padLeft(props.seconds)}</h5>
            <h5>Sekunden</h5>
          </div>
        </Row>
      </Row>
    );
  }

  return <Countdown date="2024-10-05T15:00:00.000Z" renderer={renderTime} />;
}
