import { Col, Row } from "react-bootstrap";
import darwinImage from "../../../assets/ansprechpartner/darwin.png";
import wirImage from "../../../assets/ansprechpartner/wir.jpg";
import kimImage from "../../../assets/ansprechpartner/kim.png";
import { AnsprechpartnerCard } from "./ansprechpartner-card";
import { Element } from "react-scroll";

function Ansprechpartner() {
  return (
    <Element name="ansprechpartner">
      <Col className="section ansprechpartner">
        <Row className="section-header">Ansprechpartner</Row>
        <Row>
          <AnsprechpartnerCard
            name="Nadine und Timo"
            image={wirImage}
            role="Brautpaar"
            // kontakt="0152 01710519"
            // kontakt2="0176 31581985"
          />
        </Row>
        <Row className="trauzeugen-row">
          <AnsprechpartnerCard
            name="Kim Hilgenhof"
            image={kimImage}
            role="Trauzeugin"
            kontakt="0176 80247984"
          />
          <AnsprechpartnerCard
            name="Darwin Rosenberg"
            image={darwinImage}
            role="Trauzeuge"
            kontakt="0175 3204412"
          />
        </Row>
      </Col>
    </Element>
  );
}

export default Ansprechpartner;
