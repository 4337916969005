import { Col } from "react-bootstrap";
import { Element } from "react-scroll";
import { Parallax } from "react-parallax";
import BackgroundImg from "../../assets/home/rsvp.jpg";
import Mountain from "../../assets/flowers/mountain.png";
import Separator from "../separator";

export default function RSVP() {
  const date = "01.06.2024";

  return (
    <Element name="rsvp">
      <Parallax blur={2} bgImage={BackgroundImg} strength={200}>
        <Separator img={Mountain} style={{ width: "100%" }} />
        <Col className="section rsvp">
          <Col
            className="section-header"
            style={{
              color: "var(--text)",
            }}
          >
            Rückmeldung
          </Col>
          <div style={{ fontSize: "medium", color: "var(--text)" }}>
            (RSVP wie Tobias "<i>Buché</i>" Räwers sagt)
          </div>
          <p>
            Damit wir bei sowohl der Location als auch dem Hotel entsprechend
            Planen können, würden wir euch bitten uns bis zum {date} eure
            Teilnahme zuzusagen. Bitte schreibt dafür eine E-Mail an{" "}
            <a className="mail" href="mailto:rsvp@nadineundtimo.de">
              rsvp@nadineundtimo.de
            </a>{" "}
            mit eurer Zu-/Absage, ob Interesse an einer Übernachtung von Freitag
            oder Samstag bis Sonntag besteht, und ob es bei euch im Bezug auf
            das Essen etwas zu beachten gibt.
            <br />
            Für den Fall einer Absage werden wir auf ewig Groll hegen und eine
            Ex-Kommunikation anstreben.
          </p>
          <div style={{ height: "15cqh" }}></div>
        </Col>
      </Parallax>
    </Element>
  );
}
